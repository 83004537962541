.filter {
    bottom: 2rem;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 10;
    pointer-events: none;
}

.interactable {
    pointer-events: auto;
}

.filter-open-button {
    bottom: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
}

.filter-close-button {
    cursor: pointer;
    bottom: 2rem;
}

.filter-close-icon {
    transition: transform 0.35s ease;
}

.filter-menu {
    transition: transform 0.35s ease;
    transform: translateX(-100%);
    z-index: 11;
    position: fixed;
    bottom: 0;
    top: 0;
}

.filter-menu.show {
    transform: translateX(0);
}

.filter-notification-message {
    text-align: end;
}

.filter-content {
    overflow-y: auto;
    max-width: 35%;
    height: 100%;
    min-width: 420px;
}

.rotate180 {
    transform: rotate(180deg);
}

@media screen and (max-width: 1200px) {
    .filter-content {
        max-width: 50%;
        min-width: 420px;
    }
}

@media screen and (max-width: 576px) {
    .filter-notification-message {
        text-align: center;
    }

    .filter-content {
        max-width: 100%;
        max-height: 100%;
        min-width: 260px;
    }
}
