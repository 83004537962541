.menu {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 9;
}

.spacer {
    width: 0.5rem;
}

.navbar {
    display: block;
}

.navbar-brand {
    white-space: normal;
    word-break: break-word;
}

.nav-link {
    border-radius: 0.25rem !important;
    color: rgba(0, 0, 0, 0.7) !important;
}

.nav-link.active {
    color: #2196F3 !important;
}

.nav-link:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
    cursor: pointer !important;
}

.updated-info-lg {
    display: inline-block;
    font-size: 80%;
    font-style: italic;
}

.updated-info-sm {
    display: none;
    font-size: 80%;
    font-style: italic;
}

@media screen and (max-width: 576px) {
    .updated-info-lg {
        display: none;
    }

    .updated-info-sm {
        display: inline-block;
    }
}
